.scale-popup {
  transform: translate(-50%, -50%) scale(0.5);
  opacity: 0;
  visibility: hidden;
  transition: 0.4s;
  -webkit-transform: translate(-50%, -50%) scale(0.5);
  -moz-transform: translate(-50%, -50%) scale(0.5);
  -ms-transform: translate(-50%, -50%) scale(0.5);
  -o-transform: translate(-50%, -50%) scale(0.5);
  -webkit-transition: 0.4s;
  -moz-transition: 0.4s;
  -ms-transition: 0.4s;
  -o-transition: 0.4s;
}

.scale-popup-scaling {
  transform: translate(-50%, -50%) scale(1);
  opacity: 1;
  visibility: visible;
  -webkit-transform: translate(-50%, -50%) scale(1);
  -moz-transform: translate(-50%, -50%) scale(1);
  -ms-transform: translate(-50%, -50%) scale(1);
  -o-transform: translate(-50%, -50%) scale(1);
}
