.loading-btn {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  border: 1px solid #ddd;
  border-top-color: var(--primary-color);
  animation: loading 0.6s linear infinite;
  margin: 0 auto ;
}

.big-loading {
  width: 100px;
  height: 100px;
  border: 3px solid #ddd;
  border-top-color: var(--primary-color);
}

@keyframes loading {
  to {
    transform: rotate(360deg);
  }
}
