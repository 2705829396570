.dashboard{
  direction: rtl;
  background: #E1E6F4;
  min-height: 100vh;
}
.ar{
  direction: rtl;
}
.line{
  position: relative;
}
.line::before{
  bottom: -10px;
  content: "";
  height: 3px;
  position: absolute;
  right: 0;
  background-color: #fff;
  width: 120px;
}
.line::after{
  content: "";
  height: 3px;
  width: 40px;
  position: absolute;
  bottom: -10px;
  right: 0;
  background-color: #000;
}
.form-control:focus {
  border-color: #3498db63;
  box-shadow: 0 0 0 0.25rem #3498db63;
}
.err{
  color: red;
}
#custom-switch {
  width: 50px !important;
  height: 20px !important;
}