.top-bar {
  position: fixed;
  height: 70px;
  width: 100%;
  left: 0;
  top: 0;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
  z-index: 3;
  padding: 0 20px;
  background-color: white;
}
.side-bar {
  top: 70px;
  background-color: white;
  height: 100vh;
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.1);
  z-index: 4;
}
.side-bar-link {
  color: #495057;
  font-size: 18px;
  transition: 0.3s;
  margin: 8px;
  border-radius: 5px;
}
.side-bar .active {
  background-color: #f2f3fe;
  color: #038edc;
}
.side-bar .active:hover {
  color: #038edc;
}
.side-bar-link:hover {
  color: rgba(0, 0, 0, 0.884);
}
a {
  text-decoration: none;
}
