.operations {
  width: 35px;
  height: 35px;
  line-height: 35px;
  cursor: pointer;
  user-select: none;
}

.image-gallery-image {
  width: 100% !important;
  height: 100% !important;
  display: block !important;
}
.image-gallery-slide {
  height: 350px !important;
}
.fullscreen .image-gallery-slide {
  height: 100vh !important;
  width: 100vw;
}

.image-gallery-icon {
  padding: 0 5px !important;
  border-radius: 50%;
  color: rgba(0, 0, 0, 0.5);
  z-index: 1;
}
@media (min-width: 1200px) {
  .image-gallery-icon{
    padding: 0 25px !important;
  }
}

.image-gallery-icon svg {
  width: 25px !important;
  height: 25px !important;
}

.notify-me.modal-header {
  border-bottom: 0px !important;
}
