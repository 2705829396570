/* Submit */
.spinner-container-submit {
  position: fixed;
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(154, 163, 167, 0.3);
  top: 0;
  z-index: 1;
}

.spinner {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin: 0 10px;
  border: 4px solid #7e7c7c;
  border-top: 4px solid white;
  animation: rotating-spinner 1s  linear infinite;
}
@keyframes rotating-spinner {
  from {
    transform: rotate(0deg);
  }
  to{
    transform:rotate(360deg) ;
  }
}