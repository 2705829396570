.move-icon,
.move-icon-ar {
  transform: translateY(10px);
  transition: 0.3s;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s;
  -webkit-transform: translateY(10px);
  -moz-transform: translateY(10px);
  -ms-transform: translateY(10px);
  -o-transform: translateY(10px);
}

.product-card:hover .move-icon {
  opacity: 1 !important;
  transform: translate(-10px, 10px);
  -webkit-transform: translate(-10px, 10px);
  -moz-transform: translate(-10px, 10px);
  -ms-transform: translate(-10px, 10px);
  -o-transform: translate(-10px, 10px);
}

.product-card:hover .move-icon-ar {
  opacity: 1 !important;
  transform: translate(10px, 10px);
  -webkit-transform: translate(10px, 10px);
  -moz-transform: translate(10px, 10px);
  -ms-transform: translate(10px, 10px);
  -o-transform: translate(10px, 10px);
}
