:root {
  --primary-color: #33A2DB;
  --secondery-color: #58595b;
}
/* Fonts */
body {
  font-family: "Work Sans", sans-serif !important;
}

.f-pop {
  font-family: "Poppins", sans-serif !important;
}

.f-cairo {
  font-family: "IBM Plex Sans Arabic", sans-serif !important;
}
/* Background */
.bg-custom-primary {
  background-color: var(--primary-color) !important;
}
.bg-custom-grey {
  background-color: #f2f4f6 !important;
}
.bg-custom-grey-2 {
  background-color: #e2e2e2 !important;
}
.bg-custom-red {
  background-color: #c32830 !important;
}

.bg-main-color {
  background-color: var(--primary-color);
}

/* Color */

.price-color {
  color: #77a464;
}

.primary {
  color: var(--primary-color);
}

.gray {
  color: var(--secondery-color);
}

/* Width fit Content */
.fit-content {
  width: fit-content;
}
.h-100vh {
  height: 100vh;
}
@media (max-width: 768px) {
  .row-gap-6{
    row-gap: 5rem !important;
  }
}
.row-gap-6{
  row-gap: 6rem;
}
.column-gap-5{
  column-gap: 5rem;
}
.pointer{
  cursor: pointer;
}

.link-whtasapp:hover .contact-whatsapp {
  opacity: 1;
  left: -215px;
  top: -4px;
}
.contact-whatsapp{
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  transition: all 0.3s ease;
  color: white;
  background-color: #00BE21;
  padding: 5px 10px;
  border-radius: 12px 0 0 12px;
  margin-top: 10px;
}
/* Hide Input Number Arrows */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}