.arrow {
  margin: auto;
  text-align: center;
  width: 25px;
  height: 25px;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.5);
  transition: 0.3s;
}
.arrow:hover {
  color: var(--primary-color);
  filter: drop-shadow(0 2px 2px #1a1a1a)
}
