#navbar {
  height: 70px;
}
#navbar .navbar-nav .nav-link {
  color: var(--secondery-color);
  font-size: 15px;
  font-weight: 600;
}
#navbar .nav-link.active::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: var(--primary-color);
  left: 0;
  bottom: 20px;
}

/* modal */
.btn-modal {
  border-radius: 30px !important;
  transition: 0.3s;
}
.add-to-cart {
  transition: 0.3s;
  flex-grow: 1;
}
.btn-modal:hover,
.add-to-cart:hover {
  background-color: RGB(58, 58, 58) !important;
  color: white !important;
}
.modal-backdrop.fade.show {
  z-index: 1;
}
.modal-dialog {
  margin-top: 100px !important;
}

/* Media Query */
@media (max-width: 992px) {
  #navbar .nav-link.active::before {
    bottom: 0;
  }
}
